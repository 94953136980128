<template>
  <div class="introduceWrapper">
    <div class="pcWrapper" v-if="!isMobile">
      <div class="firstBannerWrapper">
        <img src="../../assets/images/introduce/firstBack.png" alt="" />
        <!-- <div class="textBox">
          <div class="firstRow1">行业格局已经发生巨变</div>
          <div class="firstRow2">我们为您提供场景化的</div>
          <div class="firstRow3">深度行业解决方案</div>
        </div> -->
        <div class="textBox">
          <div class="firstRow1">艾融致力于推动</div>
          <div class="firstRow2">数字化运营能力建设</div>
          <!-- <div class="firstRow3">深度行业解决方案</div> -->
        </div>
      </div>
      <div class="secondWrapper">
        <div class="secontTitle">我们可以为您提供的能力</div>
        <div class="secContent">
          <div class="secTopRow">
            <div v-for="(item, index) in secondDataList1" :key="index">
              <img class="topImage" :src="item.imgUrl" alt="" />
              <div class="nameDiv">{{ item.name }}</div>
              <div class="introDiv">{{ item.intro }}</div>
            </div>
          </div>
          <div class="secBottonRow">
            <div v-for="(item, index) in secondDataList2" :key="index + 10">
              <img class="topImage" :src="item.imgUrl" alt="" />
              <div class="nameDiv">{{ item.name }}</div>
              <div class="introDiv">{{ item.intro }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="thirdWrapper">
        <img src="../../assets/images/introduce/third01.png" alt="" />
        <div class="thirdTitle">请允许介绍一下艾融软件</div>
        <div class="thirdContent">
          <div class="thirdRow1">
            上海艾融软件股份有限公司是一家面向关键行业机构提供深度数字化整体解决方案的科技公司,是国家认证的高技术企业、软件企业，已通过CMMIDEV/5认证，ISO9001、ISO27001、ISO20000等质量、安全和服务体系认证。
          </div>
          <div class="thirdRow2">
            公司业务涵盖金融IT各领域，从商业银行核心业务系统、外围IT系统建设，到业务咨询、平台建设、安全保障、运营支撑、营销获客，助力客户实现数字化转型；同时，公司进军汽车产业链，推动和践行“软件定义汽车”的发展理念，助力中国汽车软件业发展。
          </div>
        </div>
      </div>
      <div class="forthWrapper">
        <div class="forthTitle">感谢这些客户使用我们的产品和服务</div>
        <div class="forthContent">
          <div class="forthRow">
            <div
              class="forthItem"
              v-for="(item, index) in forthDataList"
              :key="index + 40"
            >
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="fifthWrapper">
        <img src="../../assets/images/introduce/fifth/fifthback.png" alt="" />
        <div class="fifthTitle">与我们共同前行的伙伴们</div>
        <div class="fifthContent">
          <div class="fifthRow1">
            <div
              class="fifthItem"
              v-for="(item, index) in fifthDataList1"
              :key="index + 50"
            >
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
          <div class="fifthRow2">
            <div
              class="fifthItem"
              v-for="(item, index) in fifthDataList2"
              :key="index + 100"
            >
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m_wrapper">
      <div class="m_firstBannerWrapper">
        <img src="../../assets/images/introduce/phone/firstBack.png" alt="" />
        <!-- <div class="textBox">
          <div class="firstRow1">行业格局已经发生巨变</div>
          <div class="firstRow2">我们为您提供场景化的</div>
          <div class="firstRow3">深度行业解决方案</div>
        </div> -->
        <div class="textBox">
          <div class="firstRow1">艾融致力于推动</div>
          <div class="firstRow2">数字化运营能力建设</div>
          <!-- <div class="firstRow3">深度行业解决方案</div> -->
        </div>
      </div>
      <div class="m_secondWrapper">
        <div class="m_secontTitle">我们可以为您提供的能力</div>
        <div class="m_secContent">
          <div
            class="m_contentItem"
            v-for="(item, index) in m_secondDataList"
            :key="index"
          >
            <img class="m_topImage" :src="item.imgUrl" alt="" />
            <div class="m_nameDiv">{{ item.name }}</div>
            <div class="m_introDiv">{{ item.intro }}</div>
          </div>
        </div>
      </div>
      <div class="m_thirdWrapper">
        <div class="m_thirdTitle">请允许介绍一下艾融软件</div>
        <div class="m_thirdContent">
          <div class="m_thirdRow1">
            上海艾融软件股份有限公司是一家面向关键行业机构提供深度数字化整体解决方案的科技公司，是国家认证的高技术企业、软件企业，已通过CMMIDEV/5认证，ISO9001、ISO27001、ISO20000等质量、安全和服务体系认证。
          </div>
          <div class="m_thirdRow2">
            公司业务涵盖金融IT各领域，从商业银行核心业务系统、外围IT系统建设，到业务咨询、平台建设、安全保障、运营支撑、营销获客，助力客户实现数字化转型；同时，公司进军汽车产业链，推动和践行“软件定义汽车”的发展理念，助力中国汽车软件业发展。
          </div>
        </div>
        <img src="../../assets/images/introduce/third01.png" alt="" />
      </div>
      <div class="m_forthWrapper">
        <div class="m_forthTitle">感谢这些客户使用我们的产品和服务</div>
        <div class="m_forthContent">
          <div
            class="m_forthItem"
            v-for="(item, index) in m_forthDataList"
            :key="index + 400"
          >
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
      <div class="m_fifthTitle">与我们共同前行的伙伴们</div>
      <div class="m_fifthWrapper">
        <img src="../../assets/images/introduce/fifth/fifthback.png" alt="" />
        <div class="m_fifthContent">
          <div class="m_fifthRow1">
            <div
              class="m_fifthItem"
              v-for="(item, index) in m_fifthDataList1"
              :key="index + 50"
            >
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
          <div class="m_fifthRow2">
            <div
              class="m_fifthItem"
              v-for="(item, index) in m_fifthDataList2"
              :key="index + 100"
            >
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "../../utils/index";

export default {
  data() {
    return {
      isMobile: false,
      m_secondDataList: [
        {
          id: "13",
          name: "经验丰富",
          intro:
            "拥有十余年大型金融科技软件工程项目经验，服务大型金融机构客户数十家。拥有成熟、系统的方法论和产品线，可以为您考虑更多。",
          imgUrl: require("../../assets/images/introduce/second01.png"),
        },
        {
          id: "7",
          name: "多地团队",
          intro:
            "在北京、上海、广州等地均拥有当地完整服务团队。客户在哪，我们就在哪。贴身全流程服务，保证项目顺畅高效。",
          imgUrl: require("../../assets/images/introduce/second02.png"),
        },
        {
          id: "1",
          name: "整体解决方案",
          intro:
            "多元化产品涵盖金融IT各领域、汽车软件领域，基于互联网、大数据、机器学习、区块链、人工智能等先进技术应用，从无到有，从交付到运营，提供一揽子解决方案。",
          imgUrl: require("../../assets/images/introduce/second03.png"),
        },
        {
          id: "20",
          name: "个性定制",
          intro:
            "拥有体系完善的在线存款,在线支付,在线贷款,在线运营,司法级在线身份认证等系列产品及企业级定制开发服务能力。",
          imgUrl: require("../../assets/images/introduce/second04.png"),
        },
        {
          id: "21",
          name: "高效专业",
          intro: "成熟的项目经验和皮实的团队作风,基于您的需要给出最优解。",
          imgUrl: require("../../assets/images/introduce/second05.png"),
        },
        {
          id: "22",
          name: "数字化系统集成",
          intro:
            "北交所上市企业,上千名员工服务于各大金融结构。可靠的技术及管理体系,确保万无一失。",
          imgUrl: require("../../assets/images/introduce/second06.png"),
        },
      ],
      m_forthDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth01.png"),
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth02.png"),
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth03.png"),
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth04.png"),
        },
        {
          id: "5",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth05.png"),
        },
        {
          id: "6",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth06.png"),
        },
        {
          id: "7",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth07.png"),
        },
        {
          id: "8",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth08.png"),
        },
        {
          id: "9",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth09.png"),
        },
        {
          id: "10",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth10.png"),
        },
        {
          id: "11",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth11.png"),
        },
        {
          id: "12",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth12.png"),
        },
        {
          id: "13",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth13.png"),
        },
        {
          id: "14",
          imgUrl: require("../../assets/images/introduce/forth/forth14.png"),
        },
        {
          id: "15",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth15.png"),
        },
        {
          id: "16",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth16.png"),
        },
        {
          id: "17",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth17.png"),
        },
        {
          id: "18",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth18.png"),
        },
        {
          id: "19",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth19.png"),
        },
        {
          id: "20",
          imgUrl: require("../../assets/images/introduce/phone/forth/forth20.png"),
        },
      ],
      forthDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/introduce/forth/forth01.png"),
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/introduce/forth/forth02.png"),
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/introduce/forth/forth03.png"),
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/introduce/forth/forth04.png"),
        },
        {
          id: "5",
          imgUrl: require("../../assets/images/introduce/forth/forth05.png"),
        },
        {
          id: "6",
          imgUrl: require("../../assets/images/introduce/forth/forth06.png"),
        },
        {
          id: "7",
          imgUrl: require("../../assets/images/introduce/forth/forth07.png"),
        },
        {
          id: "8",
          imgUrl: require("../../assets/images/introduce/forth/forth08.png"),
        },
        {
          id: "9",
          imgUrl: require("../../assets/images/introduce/forth/forth09.png"),
        },
        {
          id: "10",
          imgUrl: require("../../assets/images/introduce/forth/forth10.png"),
        },
        {
          id: "11",
          imgUrl: require("../../assets/images/introduce/forth/forth11.png"),
        },
        {
          id: "12",
          imgUrl: require("../../assets/images/introduce/forth/forth12.png"),
        },
        {
          id: "13",
          imgUrl: require("../../assets/images/introduce/forth/forth13.png"),
        },
        {
          id: "14",
          imgUrl: require("../../assets/images/introduce/forth/forth14.png"),
        },
        {
          id: "15",
          imgUrl: require("../../assets/images/introduce/forth/forth15.png"),
        },
        {
          id: "16",
          imgUrl: require("../../assets/images/introduce/forth/forth16.png"),
        },
        {
          id: "17",
          imgUrl: require("../../assets/images/introduce/forth/forth17.png"),
        },
        {
          id: "18",
          imgUrl: require("../../assets/images/introduce/forth/forth18.png"),
        },
        {
          id: "19",
          imgUrl: require("../../assets/images/introduce/forth/forth19.png"),
        },
        {
          id: "20",
          imgUrl: require("../../assets/images/introduce/forth/forth20.png"),
        },
      ],
      m_fifthDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth01.png"),
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth02.png"),
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth03.png"),
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth04.png"),
        },
        {
          id: "5",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth05.png"),
        },
        {
          id: "6",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth06.png"),
        },
        {
          id: "7",
          imgUrl: require("../../assets/images/introduce/phone/fifth/fifth07.png"),
        },
      ],
      fifthDataList1: [
        {
          id: "1",
          imgUrl: require("../../assets/images/introduce/fifth/fifth01.png"),
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/introduce/fifth/fifth02.png"),
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/introduce/fifth/fifth03.png"),
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/introduce/fifth/fifth04.png"),
        },
      ],
      fifthDataList2: [
        {
          id: "5",
          imgUrl: require("../../assets/images/introduce/fifth/fifth05.png"),
        },
        {
          id: "6",
          imgUrl: require("../../assets/images/introduce/fifth/fifth06.png"),
        },
        {
          id: "7",
          imgUrl: require("../../assets/images/introduce/fifth/fifth07.png"),
        },
      ],
    };
  },
  created() {},
  computed: {
    secondDataList1() {
      return this.m_secondDataList.slice(0, 3);
    },
    secondDataList2() {
      return this.m_secondDataList.slice(-3);
    },
    m_fifthDataList1() {
      return this.m_fifthDataList.slice(0, 4);
    },
    m_fifthDataList2() {
      return this.m_fifthDataList.slice(-3);
    },
  },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
     getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.introduceWrapper {
  background: #ffffff;
  .pcWrapper {
    .firstBannerWrapper {
      position: relative;
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
      }
      .textBox {
        position: absolute;
        top: 212px;
        left: 129px;
        width: 460px;
        height: 210px;
        // background: tomato;
        text-align: left;
        .firstRow1 {
          color: #fff;
          font-size: 40px;
          font-weight: bold;
        }
        .firstRow2 {
          color: #fff;
          font-size: 40px;
          font-weight: bold;
        }
        .firstRow3 {
          color: #fff;
          font-size: 40px;
        }
      }
    }
    .secondWrapper {
      .secontTitle {
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .secContent {
        width: 75%;
        margin: 24px auto;
        height: 500px;
        // background: green;
        .secTopRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          font-size: 16px;
          .topImage {
            width: 100px;
            height: 100px;
          }
          .nameDiv {
            font-size: 20px;
            font-weight: bold;
            margin-top: 10px;
          }
          .introDiv {
            margin-top: 10px;
            width: 300px;
            text-align: left;
            font-size: 15px;
            line-height: 22px;
          }
        }
        .secBottonRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          margin-bottom: 40px;
          .topImage {
            width: 100px;
            height: 100px;
          }
          .nameDiv {
            font-size: 20px;
            font-weight: bold;
            margin-top: 10px;
          }
          .introDiv {
            width: 300px;
            text-align: left;
            font-size: 15px;
            margin-top: 10px;
            line-height: 22px;
          }
        }
      }
    }
    .thirdWrapper {
      margin-top: 80px;
      overflow: hidden;
      position: relative;
      img {
        float: left;
        width: 100%;
        height: auto;
      }
      .thirdTitle {
        text-align: center;
        position: absolute;
        top: 50px;
        left: 13%;
        height: 30px;
        z-index: 5;
        color: #444;
        font-size: 30px;
      }
      .thirdContent {
        position: absolute;
        z-index: 5;
        color: #333;
        width: 330px;
        top: 110px;
        left: 13%;
        font-size: 16px;
        text-align: left;
        .thirdRow1 {
          margin-top: 10px;
          text-indent: 2em;
        }
        .thirdRow2 {
          margin-top: 10px;
          text-indent: 2em;
        }
      }
    }
    .forthWrapper {
      background: #fff;
      .forthTitle {
        text-align: center;
        font-size: 30px;
        margin-top: 48px;
        margin-bottom: 48px;
        color: #333;
      }
      .forthContent {
        width: 75%;
        margin: 24px auto;
        // background: green;
        .forthRow {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .forthItem {
            width: 17%;
            margin-top: 10px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .fifthWrapper {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        float: left;
      }
      .fifthTitle {
        width: 100%;
        position: absolute;
        color: #333;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
      }
      .fifthContent {
        position: absolute;
        width: 75%;
        top: 110px;
        left: 13%;
        // background: green;
        .fifthRow1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .fifthItem {
            width: 150px;
            height: 150px;
            img {
              width: 150px;
              height: 150px;
            }
          }
        }
        .fifthRow2 {
          width: 100%;
          display: flex;
          justify-content: space-around;
          .fifthItem {
            width: 150px;
            height: 150px;
            img {
              width: 150px;
              height: 150px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    .m_firstBannerWrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        // height: 339px;
        height: auto;
      }
      .textBox {
        position: absolute;
        top: 35%;
        left: 10px;
        width: 160px;
        height: 70px;
        // background: tomato;
        text-align: left;
        .firstRow1 {
          color: #fff;
          font-size: 16px;
        }
        .firstRow2 {
          color: #fff;
          font-size: 16px;
          font-weight: bolder;
        }
        .firstRow3 {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .m_secondWrapper {
      .m_secontTitle {
        text-align: center;
        font-size: 16px;
        margin-top: 40px;
        margin-bottom: 20px;
        color: #333;
        font-family: PingFangSC-Semibold;
      }
      .m_secContent {
        width: 100%;
        margin: 18px auto;
        height: 550px;
        // background: green;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .m_contentItem {
          // background: pink;
          word-wrap: break-word;
          word-break: normal;
          overflow: hidden;
          width: 44%;
          height: 170px;
          margin-top: 10px;
          .m_topImage {
            width: 40px;
            height: 40px;
          }
          .m_nameDiv {
            font-size: 12px;
            font-weight: bold;
            margin-top: 10px;
          }
          .m_introDiv {
            margin-top: 10px;
            text-align: left;
            font-size: 11px;
          }
        }
      }
    }
    .m_thirdWrapper {
      margin-top: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
      .m_thirdTitle {
        text-align: center;
        height: 30px;
        color: #333;
        font-size: 16px;
        font-family: PingFangSC-Semibold;
      }
      .m_thirdContent {
        margin: 5px auto;
        color: #333;
        width: 95%;
        font-size: 12px;
        text-align: left;
        .m_thirdRow2 {
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
      .forthWrapper {
        background: #fff;
        .forthTitle {
          text-align: center;
          font-size: 30px;
          margin-top: 48px;
          margin-bottom: 48px;
          color: #333;
        }
        .forthContent {
          width: 75%;
          margin: 24px auto;
          // background: green;
          .forthRow {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .forthItem {
              width: 17%;
              margin-top: 10px;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .m_forthWrapper {
      background: #fff;
      .m_forthTitle {
        text-align: center;
        font-size: 16px;
        margin-top: 36px;
        margin-bottom: 20px;
        color: #333;
        font-family: PingFangSC-Semibold;
      }
      .m_forthContent {
        width: 99%;
        margin: 24px auto;
        // background: green;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .m_forthItem {
          width: 23%;
          margin-top: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    .m_fifthTitle {
      width: 100%;
      color: #333;
      font-size: 16px;
      text-align: center;
      margin-top: 36px;
      font-family: PingFangSC-Semibold;
      margin-bottom: 25px;
      font-weight: bolder;
    }
    .m_fifthWrapper {
      height: 180px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        float: left;
      }

      .m_fifthContent {
        position: absolute;
        width: 98%;
        top: 5px;
        left: 0;
        // background: green;
        .m_fifthRow1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .m_fifthItem {
            width: 20%;
            height: 20%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .m_fifthRow2 {
          width: 100%;
          display: flex;
          justify-content: space-around;
          .m_fifthItem {
            width: 20%;
            height: 20%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

